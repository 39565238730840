<script lang="ts" setup>
import {
  Navigation as SwiperNavigation,
  FreeMode as SwiperFreeMode,
} from "swiper/modules";
import type { EntryCollection } from "contentful";
import type { TypeButton } from "~/types/TypeButton";
import {
  isTypeTheme,
  isTypeTopic,
  type TypeCarouselColumn,
  type TypePromoSkeleton,
} from "~/types/contentful";

const props = defineProps<{
  fields: TypeCarouselColumn<
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >["fields"] & { button: TypeButton[] };
}>();

const promoIds = computed(() =>
  props.fields.promos.map((promo) => promo?.sys.id),
);

const { data: promos } = await useLazyFetch<
  EntryCollection<TypePromoSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">
>("/api/promos", {
  query: {
    promoIds,
  },
  transform: useCircularProtect,
});

// repeated promos for carousel loop to work properly
const repeatedPromos = computed(() =>
  promos.value?.items.concat(...Array(3).fill(promos.value.items)),
);
</script>

<template>
  <div class="carousel--column relative my-4 md:my-6">
    <Container class="">
      <div
        v-if="fields.displaySectionTitle"
        class="w-full"
      >
        <TextSectionTitle>{{ fields.title }}</TextSectionTitle>
      </div>
    </Container>
    <div class="absolute bottom-[26px] right-[40px] z-10 flex gap-3">
      <div class="button-prev">
        <NuxtIcon
          name="fairr:button-arrow"
          size="40"
          class="rotate-180 cursor-pointer text-light-royal-blue"
        />
      </div>
      <div class="button-next">
        <NuxtIcon
          name="fairr:button-arrow"
          size="40"
          class="cursor-pointer text-light-royal-blue"
        />
      </div>
    </div>
    <ClientOnly>
      <swiper-container
        :modules="[SwiperNavigation, SwiperFreeMode]"
        :loop="true"
        :centered-slides="true"
        :free-mode="true"
        :navigation="{
          nextEl: '.button-next',
          prevEl: '.button-prev',
        }"
        :breakpoints="{
          320: {
            slidesPerView: 1,
          },
          420: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 4,
          },
          1920: {
            slidesPerView: 6,
          },
        }"
        class="h-[420px] md:h-auto"
      >
        <swiper-slide
          v-for="(promo, index) in repeatedPromos"
          :key="index"
          class="border-r border-white/20"
        >
          <NuxtLink
            :to="(promo.fields.button as unknown as TypeButton[])?.[0]?.url"
          >
            <div class="absolute bottom-[88px] px-4">
              <h3 class="mb-3 text-white">
                {{ promo.fields.title }}
              </h3>

              <div
                v-for="themetopic in promo.fields.themetopic"
                :key="themetopic?.fields.title"
                class=""
              >
                <TextBadge
                  class="mb-2"
                  :colour="
                    themetopic && isTypeTheme(themetopic)
                      ? themetopic.fields.colour
                      : themetopic && isTypeTopic(themetopic)
                        ? themetopic.fields.theme?.fields.colour
                        : undefined
                  "
                >
                  {{ themetopic?.fields.title }}
                </TextBadge>
              </div>
            </div>

            <NuxtImg
              v-if="promo.fields.backgroundImage"
              :src="promo.fields.backgroundImage.fields.file?.url"
              :alt="promo.fields.backgroundImage.fields.title"
              :width="511"
              :height="734"
              fit="contain"
              class="w-full"
              loading="lazy"
            />
          </NuxtLink>
        </swiper-slide>
      </swiper-container>
    </ClientOnly>
    <div class="bottom sm:w-full" />
  </div>
</template>

<style lang="scss" scoped>
.bottom {
  &::after {
    content: "";
    position: absolute;
    bottom: -12px;
    left: 0;
    width: 100%;
    height: 12px;
    background: linear-gradient(
      to bottom,
      theme("colors.white") 0 2px,
      theme("colors.dark-blue-grey-two") 2px 4px,
      theme("colors.white") 4px 6px,
      theme("colors.dark-blue-grey-two") 6px 8px,
      theme("colors.white") 8px 10px,
      theme("colors.dark-blue-grey-two") 10px 12px
    );
  }
}
</style>
